<template>
  <b-page>

    <el-form inline>
      <el-form-item>
        <b-btn-create @click="onCreate"></b-btn-create>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      v-loading="tableLoading"
    >
      <el-table-column
        label="序号"
        type="index"
      ></el-table-column>
      <el-table-column
        label="名称"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="排序"
        prop="sort"
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
      >
        <template slot-scope="{ row }">
          <c-btn-table
            :show="['update', 'delete']"
            @update="onUpdate(row)"
            @delete="onDelete(row)"
          ></c-btn-table>
        </template>
      </el-table-column>
    </el-table>

    <b-pagination
      :page="page"
      @change="fetchList"
    ></b-pagination>

    <b-dialog
      ref="formDialog"
      width="500px"
      :title="dialogType | dict('dialogTypeDict')"
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item
          label="名称"
          prop="name"
        >
          <el-input
            v-model="formData.name"
            placeholder="请输入职位名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="排序"
          prop="sort"
        >
          <el-input
            v-model="formData.sort"
            type="number"
            placeholder="请输入排序序号"
          ></el-input>
        </el-form-item>
        <div class="g-center">
          <b-btn-confirm
            :loading="confirmLoading"
            @click="onConfirm"
          ></b-btn-confirm>
        </div>
      </el-form>
    </b-dialog>
  </b-page>
</template>

<script>
import { getPostPage, deletePost, updatePost, createPost } from '@/api/admin/post'
export default {
  data() {
    return {
      tableLoading: false,
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      tableData: [],
      confirmLoading: false,
      formData: {
        name: '',
        sort: 0,
      },
      formRules: {
        name: { required: true, message: '请输入职务名称', trigger: 'blur' },
        sort: { required: true, message: '请输入排序序号', trigger: 'blur' },
      },
      dialogType: 'create',
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.tableLoading = true
      const params = {
        page: this.page.current,
      }
      getPostPage(params)
        .then(res => {
          this.tableData = res.data
          this.page.total = res.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onCreate() {
      this.dialogType = 'create'
      this.formData = {
        name: '',
        sort: 0,
      }
      this.$refs.formDialog.open()
    },
    onUpdate(row) {
      this.dialogType = 'update'
      this.formData = {
        id: row.id,
        name: row.name,
        sort: row.sort,
      }
      this.$refs.formDialog.open()
    },
    onDelete(row) {
      this.$confirm('确定要删除当前项吗？', { type: 'warning' })
        .then(() => {
          return deletePost(row.id)
        })
        .then(res => {
          this.$message.success('修改成功')
          this.onRefresh()
        })
        .catch(() => {})
    },
    onConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          if (this.dialogType === 'update') {
            updatePost(this.formData.id, this.formData)
              .then(() => {
                this.$message.success('修改成功')
                this.onRefresh()
                this.$refs.formDialog.close()
              })
              .finally(() => {
                this.confirmLoading = false
              })
          } else {
            createPost(this.formData)
              .then(() => {
                this.$message.success('新建成功')
                this.onRefresh()
                this.$refs.formDialog.close()
              })
              .finally(() => {
                this.confirmLoading = false
              })
          }
        }
      })
    },
  },
}
</script>
